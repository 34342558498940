import organizationTypeMap from '../../../constants/organizationTypeMap';

const indicatorGroups = [
  {
    name: 'organizationForm',
    charts: [
      {
        name: 'organizationForm',
        iconMap: organizationTypeMap.organizationForm.icons,
        colorMap: organizationTypeMap.organizationForm.colors,
      },
      {
        name: 'executiveResponsibilities',
        iconMap: organizationTypeMap.executiveResponsibilities.icons,
        colorMap: organizationTypeMap.executiveResponsibilities.colors,
      },
    ],
    sideNavData: {
      name: 'Organisatievorm',
    },
  },
  {
    name: 'supervisoryIndependence',
    charts: [
      {
        name: 'supervisoryIndependence',
        iconMap: organizationTypeMap.supervisoryIndependence.icons,
        colorMap: organizationTypeMap.supervisoryIndependence.colors,
      },
      {
        name: 'supervisoryDuties',
        iconMap: organizationTypeMap.supervisoryDuties.icons,
        colorMap: organizationTypeMap.supervisoryDuties.colors,
      },
    ],
    sideNavData: {
      name: 'Toezicht',
    },
  },
];
export default indicatorGroups;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { InView } from 'react-intersection-observer';
import Img from 'gatsby-image';
import { map, prepend } from 'ramda';
import Heading from '@greenberry/salal/components/Heading';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';
import Explanation from '@greenberry/salal/components/Explanation';
import ImageInfoBlock from '@greenberry/salal/components/ImageInfoBlock';
import Card from '@greenberry/salal/components/Card';
import IconChart from '@greenberry/salal/components/Charts/Icon';
import Text from '@greenberry/salal/components/Text';
import parseTooltip from '../../../utils/parseTooltip';
import SlideIntoView from '../../../components/SlideIntoView';
import PartnershipLayout from '../../../components/PartnershipLayout';
import ThemeFooter from '../../../components/ThemeFooter';
import indicatorGroups from './utils';

const Organization = ({
  pageContext: { dato, partnership, copy, sector, ...pageContext },
}) => {
  const _partnership = Object.assign({}, dato, partnership);
  const { infoBlockTitle, infoBlockBody, heading, lead, intro } = copy;

  const { infoImage } = useStaticQuery(graphql`
    {
      infoImage: file(relativePath: { eq: "organisatie.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const introductionOptions = {
    name: 'Introductie',
    id: 'introductie',
  };

  const sideNavOptions = indicatorGroups.map(({ name, sideNavData }) => ({
    name: sideNavData.name,
    id: `${sideNavData.name}-${name}`,
  }));

  const [selectedIndicator, selectIndicator] = useState(
    introductionOptions.name
  );

  return (
    <PartnershipLayout
      partnership={_partnership}
      seoMetaTags={copy.seoMetaTags}
      sector={sector}
      sideNavOptions={prepend(introductionOptions, sideNavOptions)}
      selectedIndicator={selectedIndicator}
      {...pageContext}
    >
      <Section id="introductie">
        <ImageInfoBlock node={<Img fluid={infoImage.childImageSharp.fluid} />}>
          <InView
            threshold={1}
            onChange={inView => {
              if (inView) {
                selectIndicator(introductionOptions.name);
              }
            }}
          >
            <Heading component="h1" size="xxl" weight="bold">
              {infoBlockTitle}
            </Heading>
          </InView>
          <Text appearance="inherit" size="m">
            {infoBlockBody}
          </Text>
        </ImageInfoBlock>
      </Section>
      {(heading.trim() !== '' || lead.trim() !== '' || intro.trim() !== '') && (
        <Section>
          <Introduction
            component="h1"
            title={heading}
            text={<>{parseTooltip(lead)}</>}
          />
          <Text size="m" appearance="base">
            {parseTooltip(intro)}
          </Text>
        </Section>
      )}
      {indicatorGroups.map(({ name, charts }, index) => (
        <Section key={`${name}-group`} id={sideNavOptions[index].id}>
          <InView
            threshold={1}
            onChange={inView => {
              if (inView) {
                selectIndicator(sideNavOptions[index].name);
              }
            }}
          >
            <Heading component="h1" size="xxl" appearance="secondary">
              {parseTooltip(copy[`${name}Title`])}
            </Heading>

            <Text size="m" appearance="base">
              {parseTooltip(copy[`${name}Body`])}
            </Text>
            <SlideIntoView minHeight="250px">
              <Card>
                <IconChart
                  data={map(
                    ({ name: _name, iconMap, colorMap }) => ({
                      icon: iconMap[_partnership[_name]],
                      appearance: colorMap[_partnership[_name]],
                      name: copy[`${_name}Label`],
                      value: _partnership[_name],
                    }),
                    charts
                  )}
                />
              </Card>
            </SlideIntoView>
            {_partnership[`${name}Explanation`] && (
              <Explanation
                text={_partnership[`${name}Explanation`]}
                title="Toelichting"
              />
            )}
          </InView>
        </Section>
      ))}
      <ThemeFooter
        sector={sector}
        slug={_partnership.slug}
        prev={{
          label: 'Vorige hoofdstuk: Financiën',
          to: '/financien',
        }}
      />
    </PartnershipLayout>
  );
};

Organization.propTypes = {
  pageContext: PropTypes.shape({
    dato: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string.isRequired,
      startIntroText: PropTypes.string,
    }).isRequired,
    partnership: PropTypes.shape({
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      website: PropTypes.string.isRequired,
    }).isRequired,
    copy: PropTypes.shape({
      infoBlockTitle: PropTypes.string.isRequired,
      infoBlockBody: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      lead: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({}).isRequired,
    }).isRequired,
    sector: PropTypes.string.isRequired,
  }).isRequired,
};

export default Organization;
